// Variable overrides

// $info:                               #6accc9;
$primary:                            #D4D4D4;
$secondary:                            #6c757d;
$dark:                               #2C3753;

$gray-300:                           #cfcfcf; // lighten($gray-base, 70%);
$gray-400:                           #bcacac; // lighten($gray-base, 60%);
$gray-500:                           #a68f8f; // lighten($gray-base, 50%);
$gray-600:                           #7c7575; // lighten($gray-base, 40%);
$gray-700:                           #735c5c; // lighten($gray-base, 30%);
$gray-800:                           #3a2f2f;

$gray-900:                           #333333; //DARK components

$sidebar-bg:                         #333333;

// Costum Colors

$body-bg:                            #ffffff;
$gray-body:                          #e4e5e6;
$table-dark-bg:                      #444444;

// Custom Styles

$navbar-brand-width:                  250px;
$navbar-brand-minimized-width:        60px;

$sidebar-width:                       250px;
$sidebar-minimized-width:             60px;
$sidebar-bg:                         #FFFFFF;

$sidebar-nav-color: rgb(72, 6, 75);
$sidebar-nav-title-padding-y:         1rem;
$sidebar-nav-title-padding-x:         1.5rem;
$sidebar-nav-link-color:            #011224;
$sidebar-nav-link-hover-color: #011224;
$sidebar-nav-link-padding-y:          1rem;
$sidebar-nav-link-padding-x:          1.5rem;
$sidebar-nav-link-hover-bg:           $primary;
// $sidebar-nav-link-hover-icon-color:   #fff;
// $sidebar-nav-link-active-bg:          lighten($sidebar-bg, 5%);
// $sidebar-nav-link-active-bg:            $secondary;
$sidebar-nav-link-active-bg:          lighten($secondary, 40%);
$sidebar-nav-link-active-icon-color:  black;
$sidebar-nav-link-active-color:       black;
$sidebar-nav-link-active-borders:     0;

$sidebar-nav-dropdown-color:    #011224;

$sidebar-minimizer-bg:                rgba(65, 163, 56, 0.2);