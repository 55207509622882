// Here you can add other styles
.bodybackg {
    background-color: #ffffff;
}

.app-header .navbar-brand {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

.navbar {
    padding: 0 !important;
}

.breadcrumb {
    background-color: #e4e5e6;
}

.app-footer {
    background-color: #e4e5e6;
}

.nav-dropdown-items .nav-item a {
    padding-left: 2.5rem;
    border-left: 5px $primary solid !important;
}

.hover-shadow {
    transition: all ease-in-out $layout-transition-speed;
}

.hover-shadow:hover {
    filter: drop-shadow(0px 0px 2px rgba(0, 0, 0, 0.45));
}

.hover-opa {
    opacity: 0.7;
    transition: all ease-in-out $layout-transition-speed;
}

.hover-opa:hover {
    opacity: 1;
}

@keyframes blinker {
    50% {
        opacity: 0;
    }
}

.blink-it {
    animation: blinker 1s linear infinite;
}

.blink-it:hover {
    animation: none;
}

.skew-x {
    -ms-transform: skew(-10deg);
    -webkit-transform: skew(-10deg);
    transform: skew(-10deg);
}

.w-90 {
    width: 90%;
}

.h-90 {
    height: 90%;
}

.h-88 {
    height: 88%;
}

.sm-box {
    width: 50px;
    transition: all ease-in-out $layout-transition-speed;
    background:
        // linear-gradient(
        //     135deg,
        //     rgba(255, 255, 255, 0.2),
        //     rgba(255, 255, 255, 0.2) 10px,
        //     rgba(255, 255, 255, 0.3) 10px,
        //     rgba(255, 255, 255, 0.3) 20px
        // )
        linear-gradient(335deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0.1) 49.92%, rgba(0, 0, 0, 0.1) 50%);
}

.border-l-2 {
    border-left: 2px #bbb outset;
}

.table-pgn ul {
    margin-bottom: 0;
}

.bg-light-gray {
    color: #333;
    background: #e4e5e6;
}

.bg-trans {
    background: none !important;
}

.card-footer {
    background-color: #e9e9e9;
}

.mid-card-cont {
    width: 95%;
    margin: auto;
    border-radius: 15px;
    background-color: #e4e5e6;
    font-family: "poppins";
    font-size: 1rem;
    overflow: hidden;
    margin-bottom: 1.5rem;
    display: flex;
    transition: all ease-in-out $layout-transition-speed;
    cursor: pointer;
}

.mid-card-cont:hover {
    filter: drop-shadow(0px 0px 3px rgba(0, 0, 0, 0.2));
    opacity: 0.9;
}

.mid-card-title {
    flex: 2;
    padding: 8px;
    text-align: center;
}

.mid-card-value {
    flex: 1;
    background: #333;
    color: #ffffff;
    padding: 8px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.mini-card {
    width: 100%;
    margin: auto;
    background-color: #e4e5e6;
    font-family: "poppins";
    font-size: 0.7rem;
    overflow: hidden;
}

// a .hover-red:hover {
//     color: $danger;
// }
a {
    color: #007bff;
    text-decoration: none;
    background-color: transparent;
}

.radius-30 {
    border-radius: 30px;
}

.table-col-head {
    background-color: #e4e5e6;
    font-family: "poppins";
    font-size: 0.8rem;
    padding: 0.6rem 0.3rem !important;
}

.table-striped tbody tr:nth-of-type(odd) {
    background-color: rgba(0, 0, 0, 0.03);
}

.table-striped tbody tr:nth-of-type(odd):hover {
    background-color: rgba(0, 0, 0, 0.07);
}

.DateRangePickerInput__withBorder,
.DateInput,
.DateInput_input {
    border-radius: 15px !important;
    text-align: center;
    font-size: 12px !important;
}

.DateRangePicker,
.DateRangePickerInput {
    width: 100%;
}

.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
    background: #ea5a5a !important;
    border: 1px double #ea5a5a !important;
    color: #fff;
}

.CalendarDay__selected_span {
    background: #e68a8a !important;
    border: 1px double #ea5a5a !important;
    color: #fff;
}

.CalendarDay__hovered_span,
.CalendarDay__hovered_span:hover {
    background: #e49b9b !important;
    border: 1px double #e68a8a !important;
    color: #efefef !important;
}

.DateInput__small {
    width: 45% !important;
    padding: 0 2% !important;
}

.rbt-input-hint-container .form-control {
    border-radius: 15px !important;
    padding-left: 0.7rem;
}

.rbt-input-hint-container .form-control-sm {
    height: calc(2.0625rem + 2px);
}

.DateInput_input__focused {
    border-bottom: 2px solid #ea5a5a !important;
}

.filter-toggler {
    background-color: #111111;
    border-color: #111111;
    color: #999999;
}

.filter-toggler:focus {
    box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.3);
}

.react-bootstrap-table tr {
    cursor: pointer;
}

.react-bootstrap-table .align-middle td,
.react-bootstrap-table .align-middle th {
    vertical-align: middle;
}

.table-img-cont {
    width: 50px;
    height: 50px;
    border-radius: 30px;
    overflow: hidden;
    margin: auto;
    filter: drop-shadow(0px 0px 5px rgba(0, 0, 0, 0.1));
}

.row2 {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    margin-bottom: -30px;
}

.row3 {
    display: flex;
    flex-wrap: wrap;
    margin-right: -15px;
    margin-left: -15px;
    margin-bottom: -130px;
}

.row4 {
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
}

.fab {
    margin: 0px;
    top: auto;
    right: 20px;
    bottom: 20px;
    left: auto;
    position: fixed;
}

;

.page-item.active .page-link {
    z-index: 3;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
}

// @media (min-width: 992px) {
//     .sidebar-minimized.sidebar-fixed .sidebar {
//         overflow: hidden !important;
//     }
// }

.bg-dark {
    background-color: #343a40 !important;
}

@include media-breakpoint-up(lg) {

    //
    // Header
    //
    .header-fixed {
        .app-header {
            position: fixed;
            z-index: $zindex-sticky;
            width: inherit;
        }

        .app-body {
            margin-top: 0;
        }
    }

    //
    // Sidebar
    //
    // .sidebar-fixed {
    //     .sidebar {
    //         // margin-top: $navbar-height;
    //         margin-top: 0;
    //     }
    // }
}

.user-nav {
    position: fixed;
    top: 0;
    right: 0;
    padding: 0.3rem 0 0.3rem 0;
    margin-top: 0.6rem;
    border-bottom-left-radius: 25px;
    border-top-left-radius: 25px;
    background: rgba(0, 0, 0, 0.2);
    transition: 0.5s;
    opacity: 0.7;
    z-index: 1030;
}

@include media-breakpoint-up(lg) {
    .user-nav {
        margin-right: -1.0rem;
    }

    .user-nav:hover,
    .user-nav:active {
        margin-right: 0;
    }
}

.user-nav:hover,
.user-nav:active {
    opacity: 1;
    background: rgba(0, 0, 0, 0.4);
}

.user-nav .nav-link {
    padding: 0;
}


.app-header .nav-item .nav-link>.img-avatar,
.app-header .nav-item .avatar.nav-link>img {
    height: 20px;
    margin: 0 5px;
}

.app-header .nav-item {
    min-width: 40px;
}

.sidebar-fixed .app-header+.app-body .sidebar {
    height: calc(100vh);
}

.dash-fullpage {
    background: #203354;
    /* position: relative;   */
    // height: calc(93vh);
    overflow-x: hidden;
    overflow-y: hidden;
    min-height: (100vh - 5);
}

.dash-list-log {
    min-height: 10vh;
    max-height: 72vh;
    overflow-y: scroll !important;
    scrollbar-width: thin;
}

.dash-list-log::-webkit-scrollbar {
    width: 8px; // for vertical scroll bar
    height: 8px; // for horizontal scroll bar
    background: #A6A6A6;
}

.dash-list-log tr {
    text-align: center;
}

.dash-list-door {
    min-height: 10vh;
    max-height: 80vh;
    overflow-y: scroll !important;
    scrollbar-width: thin;
}

.dash-list-door::-webkit-scrollbar {
    width: 8px; // for vertical scroll bar
    height: 8px; // for horizontal scroll bar
    background: #A6A6A6;
}

.ag-list-door {
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    border-radius: 2px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    padding-left: 4px;
    padding-right: 4px;
    box-sizing: border-box;
}

.ag-list-door:hover {
    background-color: #e49b9b;
    // opacity: 0.5;
}
